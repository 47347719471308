<template>
    <section class="unit-dashboard">
        <Unit_LiveVideo v-show="showLiveVideo" v-model="cams" :status="videoStatus"/>
        <UnitReefer v-if="!isShowMap && hwTypeControls.show_UnitReefer"
                    :unitId="unitId"
                    :unitLmsg="unitLmsg"
                    :size-class="'reefer-full'"
                    :count-info="4"
                    :show-map.sync="isShowMap"
        />
        <section class="car-info">
            <ul class="car-info__list">
                <li class="car-info__item car-info__car" v-if="!hwTypeControls['hide_car-info']">
                    <button class="tracking-unit">
                        <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><UnitsList_unit_menu_tracking :unit-id="unitId" /></span> </span>
                    </button>
                    <SvgIcon :name="carIcon" class="icon"
                             style="transform: rotate(90deg); width: 200px; height: 62px"
                             :style="{color: unit.icon_color}" />
                    <div>
                        <h3 class="block-title block-title-center">{{ carTitle }}</h3>
                    </div>
                </li>
                <li class="car-info__item car-info__car">
                    <h3 class="block-title">{{ $t('Unit.Hardware') }}</h3>
                    <div class="info-text">
                        <span>{{ unit_hw_type }}</span>
                    </div>
                </li>
                <li class="car-info__item car-info__car">
                    <h3 class="block-title">{{$t('Unit.IMEI')}}</h3>
                    <div class="info-text">
                        <span>{{ unit.hw_id }}</span>
                    </div>
                </li>
                <li class="car-info__item car-info__car" v-if="unit.iccid">
                    <h3 class="block-title">{{ $t('Unit.Iccid') }}</h3>
                    <div class="info-text">
                        <span>{{ unit.iccid }}</span>
                    </div>
                </li>
            </ul>
            <div class="address-info">
                <div class="address-info__icon">
                    <SvgIcon class="icon main-icon" name="unit_dashboard__address-point"/>
                </div>
                <div class="address-info__body">
                    <span class="info-text">{{ $t('Unit.Address') }}</span>
                    <h3 class="block-title">{{address}}</h3>
                </div>
            </div>
        </section>
        <div class="flex-wrap" :class="{'type-reefer': hwTypeControls['type-reefer']}">
            <component :is="unitInfoComponent"
                       :value="unit"
                       :show-map.sync="isShowMap"
            />
            <section class="live-video" v-if="hasUnitActiveVideo">
                <ul class="live-video__list">
                    <li  class="live-video__items">
                        <div class="live-video__show-video">
                             <div class="live-video__item">
                                 <UnitsList_unit_status_icon :status="videoStatus" :position="'tl'" v-if="false" />
                                <!--{{videoStatus}}-->
                             </div>
                            <ul class="live-video__show-container" v-if="cams.length">
                                <li class="live-video__item" v-for="cam in cams" :key="cam.id">
                                    <label class="live-video__label">
                                        <input class="live-video__hidden-input" type="checkbox" :name="'cam'+cam.id" v-model="cam.view">
                                        <div class="live-video__title">{{ cam.name }}</div>
                                    </label>
                                </li>
                            </ul>
                            <div class="live-video__item" v-if="cams.length">
                                <button class="live-video__show-all" @click="setShowALL">
                                    Show All
                                </button>
                            </div>
                        </div>
                        <div class="live-video__live" v-if="cams.length">Live Video</div>
                        <div class="live-video__play" v-if="cams.length"
                             @click="_showLiveVideo"
                        >
                            <img src="@/assets/image/video-play.png" alt="">
                        </div>
                    </li>
                </ul>
            </section>
           <WeatherWidget :unit-id="unitId" :extended-size="2" v-else-if="type != types.reefer"/>
        </div>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Units",
        components: {
            'Unit_LiveVideo': () => import('@/components/units/Unit_LiveVideo.vue'),
            'Unit_liveVideo_control': () => import('@/components/units/Unit_liveVideo_control.vue'),
            'UnitsList_unit_menu_tracking': () => import('@/components/units/UnitsList_unit_menu_tracking.vue'),
            'UnitsList_unit_status_icon': () => import('@/components/units/UnitsList_unit_status_icon.vue'),
            'UnitReefer': () => import('@/components/units/UnitReefer.vue'),
            'Unit_info': () => import('@/components/units/info/Unit_info.vue'),
            'UnitReefer_info': () => import('@/components/units/info/UnitReefer_info.vue'),
        },
        props:{
            "showMap": Boolean,
            "showWeather": Boolean,
        },
        data() {
            return {
                types:{
                    reefer: 'globe_tracker',
                },
                sp_types:{
                    'globe_tracker': {
                        show_UnitReefer: true,
                        'hide_car-info': true,
                        'type-reefer': true,
                    },
                },
                components:[
                    { type: 'globe_tracker', component: 'UnitReefer_info', 'show_car-info': false },
                ],
                showLiveVideo: false,
                cams: [],
            }
        },
        computed:{
            ...mapGetters([
                "getAppUserSettings",
                "getAppUserUnits",
                "getTimeToday",
                "unitTracking",
                "getUnitActive",
                "hasUnitActiveVideo",
                "getUnitActiveCameras",
                "unitsByIds",
                "unitsLmsgsByIds",
                "getUnitsHwTypes",
            ]),
            unit(){
                return this.unitsByIds[this.getUnitActive] || {}
            },
            type(){
                return this.unit.hw_type || 'defaultInfo'
            },
            hwTypeControls(){
              return this.sp_types[this.type] || {}
            },
            isShowMap:{
                get(){return this.showMap},
                set(val){this.$emit('update:showMap',val)}
            },
            videoStatus(){
                return (this.unit.video || {}).status
            },
            unitInfoComponent(){
                let component = (this.components.find( c => c.type == this.type) || {}).component
                return  component ? component : 'Unit_info'
            },
            unitId(){
              return this.unit.id
            },
            unit_hw_type(){
                let hw_type = this.getUnitsHwTypes.find(type => {
                    return type.hw_type == this.unit.hw_type
                })
                return hw_type? hw_type.hw_title : this.unit.hw_type
            },
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unit.id] || {}
            },
            carIcon(){
                return this.unit.icon || 'map__car_type_pickup'
            },
            carTitle(){
                let list = []
                if(this.unit.name > '') list.push(this.unit.name)
                if(this.unit.model > '') list.push(this.unit.model)
                return  '' + list.join('/')
            },
            address(){
                return (this.unitLmsg.address > ' ') ? this.unitLmsg.address :
                    (this.unitLmsg.latlng) ? "lat: "+this.unitLmsg.latlng.lat+", lng: "+this.unitLmsg.latlng.lng : ''
            },
            camsActive(){
                let cams = this.cams.filter(c => c.view)
                return cams.length
            },
        },
        watch:{
            getUnitActive:{
                handler: function (newVal){
                    if(newVal>0) {
                        this.cams = this.getUnitActiveCameras.map(cam => {
                            return {...cam, view: false}
                        })
                    }
                },
            },
            camsActive:{
                handler: function (newVal){
                    if(this.showLiveVideo) {
                        this.$emit('update:showMap', !newVal)
                    }
                    if(!newVal){
                        this.showLiveVideo = false;
                    }
                },
            },
        },
        methods:{
            ...mapActions([
                "fetchUnitLiveVideo",
                "fetchUnitWeather",
                "fetchUnitLmsg",
            ]),
            setShowALL(){
                this.cams.forEach(e => e.view = true)
            },
            _showLiveVideo(){
                if(!this.camsActive) return false;
                this.showLiveVideo = true;
                this.$emit('update:showMap',false)
                // let cams
                // await cams = this.getUnitLiveVideo(this.getUnitActive)
                this.$api.units.liveVideo(this.getUnitActive, {}, {timeout: 60000})
                    .then((res) => {
                        console.log(res)
                        let cams = (res.data || {}).channels || []
                        this.cams = this.cams.map(cam => {
                            let _cam = cams.find(c => c.id == cam.id)
                            return {..._cam, ...cam}
                        })
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        created() {
            this.cams = this.getUnitActiveCameras
        },
        updated() {
            this.$emit('update:showWeather',this.type == this.types.reefer)
        },
        mounted() {
            setInterval(() => {
                if (this.unitId > 0) this.fetchUnitLmsg(this.unitId)
            }, 3000)
        },
    }

</script>

<style scoped>
</style>